import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton } from "@chakra-ui/react";

import logo from '../images/vente_logo.png'

export default function NavBar(props) {
    return (
        <Image
            h={{base: '55%', md: '55%'}}
            w={{base: '55%', md: '55%'}}
            src={logo}
            objectFit="cover"
        />
  );
}

