import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton } from "@chakra-ui/react";

import logo from '../images/victory_emoji.png'

export default function NavBar(props) {
    return (
        <Image
            w={{base: "50px", "md": "70px"}}
            src={logo}
            objectFit="cover"
        />
  );
}

