import React, { Component } from 'react';
import { ChakraProvider, Box, Text, Flex, Heading, extendTheme, Spacer} from '@chakra-ui/react';
import { createBreakpoints } from "@chakra-ui/theme-tools"
import { BsEnvelopeFill } from "react-icons/bs";

import VenteLogo from './components/VenteLogo'
import TickbitLogo from './components/TickbitLogo'
import SparklesEmoji from './components/SparklesEmoji'
import VictoryEmoji from './components/VictoryEmoji'

const breakpoints = createBreakpoints({
	sm: "30em",
	md: "48em",
	lg: "62em",
	"1.5lg": "986px",
	"2lg": "1080px",
	"3lg": "1140px",
	xl: "80em",
	"2xl": "96em",
})
  
const theme = extendTheme({ 
	breakpoints
})

const BACKGROUND_COLOR = "#f7f8fe"
const BACKGROUND_COLOR_2 = "#e1e4f5"
const CODE_TEXT_COLOR = "#cacfed"
const CODE_TEXT_COLOR_2 = "#979fc9"
const CONTENT_COLOR = "#3d425e"
const CONTENT_COLOR_2 = "#333857"
const CONTENT_COLOR_3 = "#545a7a"

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	};

	render() {
		return (
			<ChakraProvider theme={theme} bg="transparent">
				{/*<Flex h="100vh" direction="row" bg={"white"}>*/}
				<Flex h={{"1.5lg": "100vh"}} direction={{base: "column", "1.5lg": "row"}} bg={"white"}>
					<Flex direction="column" w={{base: "100vw", "1.5lg": "42vw"}} pt={{base: "42", "1.5lg": "0"}} pb={{base: "42", "1.5lg": "0"}} justifyContent='center' pl={{base: "10", "2lg": "20"}} pr={{base: "10", "2lg": "20"}}>
						<VictoryEmoji/>
						<Text fontSize="md" color={CODE_TEXT_COLOR} mt="30" fontFamily={"Poppins"} fontWeight={"light"}>{`<name>`}</Text>
						<Flex direction="row">
							<Heading fontFamily={"Poppins"} fontWeight={"bold"} bgGradient="linear(to-r, #65b8ff, #9d7efd, #f87b90)" bgClip="text" size="2xl">Albert<br />Granados</Heading>
							<Spacer/>
						</Flex>
						<Text fontSize="md" color={CODE_TEXT_COLOR} fontFamily={"Poppins"} fontWeight={"light"}>{`</name>`}</Text>
						<Text fontSize="md" color={CODE_TEXT_COLOR} mt="15" fontFamily={"Poppins"} fontWeight={"light"}>{`<description>`}</Text>
						<Flex direction="row">
							<Text fontSize="lg" fontWeight="medium" color={CONTENT_COLOR} fontFamily={"Poppins"} fontWeight={"medium"} mr="6px">Software Developer. Front-end oriented.</Text>
							{/*<SparklesEmoji/>*/}
						</Flex>
						<Text fontSize="md" color={CODE_TEXT_COLOR} fontFamily={"Poppins"} fontWeight={"light"}>{`</description>`}</Text>
						<Text fontSize="md" color={CODE_TEXT_COLOR} mt="15" fontFamily={"Poppins"} fontWeight={"light"}>{`<ocupation>`}</Text>
						<Text fontSize="lg" fontWeight="medium" color={CONTENT_COLOR} fontFamily={"Poppins"} fontWeight={"medium"}>Co-founder & COO at Vente.<br />Creating Tickbit.</Text>
						<Text fontSize="md" color={CODE_TEXT_COLOR} fontFamily={"Poppins"} fontWeight={"light"}>{`</ocupation>`}</Text>
						<Text fontSize="md" color={CODE_TEXT_COLOR} mt="15" fontFamily={"Poppins"} fontWeight={"light"} mb="1">{`<contact>`}</Text>
						<Flex direction="row">
							<a href="mailto:contact@albertgranados.com">
								<Flex direction="row">
									<Flex direction="row" transition="all 0.2s cubic-bezier(.08,.52,.52,1)" _hover={{bg: BACKGROUND_COLOR_2}} borderRadius="10px" bg={BACKGROUND_COLOR} overflow="hidden" alignItems="center" pl="20px" pr="20px" pt="10px" pb="10px">
										<BsEnvelopeFill color={CODE_TEXT_COLOR_2}/>
										<Text color={CODE_TEXT_COLOR_2} fontWeight="medium" fontFamily={"Poppins"} ml="16px">Say hello!</Text>
									</Flex>
								</Flex>
							</a>
							<Spacer/>
						</Flex>
						<Text fontSize="md" color={CODE_TEXT_COLOR} fontFamily={"Poppins"} fontWeight={"light"} mt="2">{`</contact>`}</Text>
					</Flex>
					<Flex direction="column-reverse" w={{base: "100vw", "1.5lg": "58vw"}} bg={BACKGROUND_COLOR} borderTopLeftRadius={40} borderBottomLeftRadius={{base: 0, "1.5lg": 40}} borderTopRightRadius={{base: 40, "1.5lg": 0}}>
						<Box pl={{base: "10", "1.5lg": "32"}} pr={{base: "10", "1.5lg": "32"}} pb={{base: "10", "1.5lg": "32"}} pt={{base: "10", "1.5lg": "20"}}>
							<Text fontSize="md" color={CODE_TEXT_COLOR} mb="18px" fontFamily={"Poppins"} fontWeight={"light"}>{`<projects>`}</Text>
							<Box mb="60px">
								<a href="https://vente.es" target="_blank">
									<Box transition="all 0.2s cubic-bezier(.08,.52,.52,1)" d="flex" _hover={{transform: "scale(0.99)", opacity: 0.8}} >
										<Box d="flex" w="90px" h="90px" borderRadius="50px" bg={"white"} mr="10" alignItems="center" justifyContent="center" pt="3px" pl="2px">
											<VenteLogo/>
										</Box>
										<Flex direction="column">
											<Heading fontFamily={"Poppins"} fontWeight={"bold"} size="xl" color={CONTENT_COLOR_2}>Vente</Heading>
											<Text fontSize="md" mt="1" fontFamily={"Poppins"} fontWeight={"regular"} color={CODE_TEXT_COLOR_2}>¿Tienes planes?</Text>
											<Text fontSize="md" mt="3" fontFamily={"Poppins"} fontWeight={"medium"} w={"80px"} color="#65b8ff">vente.es</Text>
										</Flex>
									</Box>
								</a>
							</Box>
							<Box>
								<a href="https://tickb.it" target="_blank">
									<Box transition="all 0.2s cubic-bezier(.08,.52,.52,1)" d="flex" _hover={{transform: "scale(0.99)", opacity: 0.8}} >
										<Box d="flex" minW="90px" minH="90px" maxH="90px" maxW="90px" borderRadius="50px" bg={"white"} mr="10" alignItems="center" justifyContent="center" pt="3px" pl="2px">
											<TickbitLogo/>
										</Box>
										<Flex direction="column">
											<Heading fontFamily={"Poppins"} fontWeight={"bold"} size="xl" color={CONTENT_COLOR_2}>Tickb.it</Heading>
											<Text fontSize="md" mt="1" fontFamily={"Poppins"} fontWeight={"regular"} color={CODE_TEXT_COLOR_2}>A ticket sales website blockchain based.</Text>
											<Text fontSize="md" mt="3" fontFamily={"Poppins"} fontWeight={"medium"} w={"80px"} color="#65b8ff">tickb.it</Text>
										</Flex>
									</Box>
								</a>
							</Box>
							<Text fontSize="md" color={CODE_TEXT_COLOR} fontFamily={"Poppins"} fontWeight={"light"} mt="10px">{`</projects>`}</Text>
						</Box>
					</Flex>
				</Flex>
			</ChakraProvider>
		);
	}
}

export default App;